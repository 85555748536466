@import "../../global.scss";
.roadmap{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    height: auto;
    .scroll{
        position: absolute;
        top :-5rem
    }
    h1{
        font-size: 3rem;
        color: white;
        width: 80%;
        font-family: ZenDots;
        margin-bottom: 1rem;
        @include mobile_potrait{
            font-size: 1.5rem;
            text-align: center;
        }
        @include mobile_landscape{
            font-size: 1.5rem;
        }
    }
    p{
        font-size: 1rem;
        color:#aaa;
        width: 80%;
        @include mobile_potrait{
            font-size: 0.7rem;
        }
        @include mobile_landscape{
            font-size: 0.7rem;
        }
    }
    .roadmapContent{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 5rem;
        @include mobile_potrait{
            width: 90%;
            height: 90vh;
        }
        @include mobile_landscape{
            width: 80vw;
        }
        .node{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-bottom: 5rem;
            // border-left: 3px #c104de dotted;
            border-left: 3px #c104de solid;
            position: relative;
            @include mobile_potrait{
                height: 25%;
                padding-bottom: 1rem;
            }
            @include mobile_landscape{
                padding-bottom: 1rem;
            }
            .circle{
                width: 1.5rem;
                height: 1.5rem;
                border: 1px #c104de solid;
                border-radius: 50%;
                left: -0.9rem;
                top: 0;
                position: absolute;
                background-color: #222;
                display: flex;
                align-items: center;
                justify-content: center;
                &::after{
                    content: '';
                    width: 0.8rem;
                    height: 0.8rem;
                    background-color: #fff;
                    border-radius: 50%;
                }
            }
            .line{
                position: absolute;
                top: 0.9rem;
                left: 1rem;
                width: 8%;
                border-top: 1px solid #c104de;
                @include mobile_potrait{
                    display: none;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }

            }
            .level{
                width: 40%;
                height: 2rem;
                background-color: #c104de33;
                margin-left: 10%;
                z-index: 1;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                position: relative;
                color: #fff;
                @include mobile_potrait{
                    font-size: 0.6rem;
                    width: calc(90% - 4rem);
                    margin-left: 10% !important;
                }
                @include mobile_landscape{
                    font-size: 0.6rem;
                }
                &::before{
                    content: '';
                    width: 100%;
                    height: 5px;
                    background-color: #c104de33;
                    position: absolute;
                    bottom: -10px;
                }
                span{
                    color : #c104de;
                    margin-left: 1rem;
                    margin-right: 0.5rem;
                }
            }
            .desc{
                width: calc(90% - 4rem);
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                max-height: 10rem;
                position: relative;
                padding: 2rem;
                margin-left: 10%;
                @include mobile_potrait{
                    width: calc(90% - 2rem);
                    padding: 1rem;
                }
                @include mobile_landscape{
                    width: calc(90% - 2rem);
                    padding: 1rem;
                }
                p{
                    width: 40%;
                    color: #fff;
                    @include mobile_potrait{
                        font-size: 0.6rem;
                        line-height: 1;
                        width: 100%;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                }
                @include mobile_potrait{
                    flex:0.9;
                }
                img{
                    height: 15rem;
                    position: absolute;
                    bottom : 0;
                    z-index: 1;
                    @include mobile_potrait{
                        display: none;
                    }
                    @include mobile_landscape{
                        height: 8rem;
                    }
                }
                .bar{
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    left : 0;
                    @include mobile_potrait{
                        display: flex;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
    .effect{
        width: 50vw;
        height: 50vw;
        position: absolute;
        border-radius: 50%;
        background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
        opacity: 0.3;
        z-index: 0;
        right: -20vw;
    }
    .effect1{
        width: 40vw;
        height: 40vw;
        position: absolute;
        border-radius: 50%;
        background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
        opacity: 0.2;
        z-index: 0;
        left: -20vw;
        bottom : 10%;
    }
}