@import "../../global.scss";
.faq{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    // overflow: hidden;
    @include mobile_potrait{
        height: auto;
    }
    .coin{
        width: 80%;
        margin-bottom: -6rem;
        @include mobile_potrait{
            margin-top: -3rem;
            margin-bottom: -1rem;
        }
        @include mobile_landscape{
            margin-top: -3rem;
            margin-bottom: -3rem;
        }
    }
    .scroll{
        position: absolute;
        top :-10rem
    }
    .faqContent{
        width: 80%;
        margin-bottom: 2vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        @include mobile_potrait{
            width: 90%;
            height: auto;
        }
        
        .wrapper{
            width: 100%;
            height: 60vh;
            max-width: 1440px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            z-index: 1;
            @include mobile_potrait{
                height: auto;
                flex-direction: column;
            }
            .left{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h1{
                    font-size: 3rem;
                    color: white;
                    width: 90%;
                    font-family: ZenDots;
                    margin-bottom: 1rem;
                    @include mobile_potrait{
                        font-size: 1.5rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 1.5rem;
                    }
                }
                p{
                    font-size: 1rem;
                    color:#aaa;
                    width: 90%;
                    @include mobile_potrait{
                        font-size: 0.7rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.7rem;
                    }
                }
                .myTab{
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin-top: 1rem;
                    @include mobile_landscape{
                        margin-top: 0.5rem;
                    }
                    .tabList{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: row;
                        height: 2rem;
                        border-bottom: 1px #aaaaaa77 solid;
                        .tab{
                            color: #aaa;
                            font-family: ZenDots;
                            font-size: 1rem;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            cursor: pointer;
                            transition: all 0.3s ease;
                            margin-right: 1rem;
                            &::before{
                                content: "";
                                width: 100%;
                                position: absolute;
                                height: 3px;
                                background-color: #c104de;
                                bottom: -1px;
                                z-index: 1;
                                opacity: 0;
                                transition: all 0.3s ease;
                            }
                            @include mobile_potrait{
                                font-size: 0.7rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.7rem;
                            }
                        }
                        .activeTab{
                            color: #fff;
                            &::before{
                                opacity: 1;
                            }

                        }

                    }
                    .tabBody{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        min-height: 50vh;
                        @include mobile_potrait{
                        }
                        @include mobile_landscape{
                        }
                        .tabContent{
                            margin-top: 1rem;
                            width: 100%;
                            position: absolute;
                            transition: all 0.3s ease;
                            opacity: 0;
                            top: 0;
                            .line{
                                width: calc(100% - 2rem);
                                padding: 1rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                transition: all 0.4s ease;
                                background-color: #c104de33;
                                margin-bottom: 1rem;
                                border-radius: 10px;
                                cursor: pointer;
                                @include mobile_potrait{
                                    width: calc(100% - 1rem);
                                    padding: 0.5rem;
                                }
                                @include mobile_landscape{
                                    width: calc(100% - 1rem);
                                    padding: 0.5rem;
                                }
                                .question{
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    color: white;
                                    .show{
                                        cursor: pointer;
                                        font-size: 1rem;
                                        transition: all 0.4s ease;
                                        @include mobile_potrait{
                                            font-size: 0.8rem;
                                        }
                                        @include mobile_landscape{
                                            font-size: 0.8rem;
                                        }
                                    }
                                    p{
                                        font-size: 1.2rem;
                                        color: #fff;
                                        transition: all 0.4s ease;
                                        @include mobile_potrait{
                                            font-size: 0.8rem;
                                        }
                                        @include mobile_landscape{
                                            font-size: 0.8rem;
                                        }
                                    }
                                }
                                .answer{
                                    width: 100%;
                                    color: #aaa;
                                    font-size: 1rem;
                                    overflow: hidden;
                                    margin-bottom: 1vh;
                                    transition: all 0.4s ease;
                                    @include mobile_potrait{
                                        font-size: 0.8rem;
                                    }
                                    @include mobile_landscape{
                                        font-size: 0.8rem;
                                    }
                                }
                            }

                        }
                        .activeTab{
                            opacity: 1;
                            z-index: 1;
                        }
                        
                    }
                }
            }
            .right{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile_potrait{
                    width: 100%;
                }
                @include mobile_landscape{
                    
                }
                img{
                    width: 90%;
                }
            }
        }
    }
    .effect{
        width: 50vw;
        height: 50vw;
        position: absolute;
        border-radius: 50%;
        background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
        opacity: 0.3;
        z-index: 0;
        right: -20vw;
    }
}