@import "../../global.scss";
.topbar{
    width: 100%;
    height: 5rem;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    position: fixed;
    justify-content: space-between;
    top:0;
    z-index: 9;
    backdrop-filter: blur(5px);
    @include mobile_potrait{
        height: 6rem;
        flex-direction: column;
    }
    @include mobile_landscape{
        height: 3rem;
    }
    .logo{
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 2rem;
        @include mobile_potrait{
            margin-left: 1rem;
            height: auto;
            width: 100%;
            justify-content: flex-start;
            margin-bottom: 0.2rem;
        }
        @include mobile_landscape{
            margin-left: 0.5rem;
        }
        a{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            @include mobile_potrait{
                font-size: 2rem;
            }
            @include mobile_landscape{
                font-size: 2rem;
            }
            img{
                height: 4.5vw;
                filter: drop-shadow(3px 3px 5px #000);
                @include mobile_potrait{
                    height: 3rem;
                }
                @include mobile_landscape{
                    height: 2rem;
                }
            }
            h1{
                color: #fff;
                font-size: 2.5vw;
                margin-left: 1rem;
                text-shadow: 3px 3px 5px #000;
                font-family: ZenDots;
                @include mobile_potrait{
                    font-size: 1.5rem;
                }
                @include mobile_landscape{
                    font-size: 1rem;
                }
            }
            
        }
    }
    .btns{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3rem;
        background-size: 100% 100%;

        @include mobile_potrait{
            justify-content: space-between;
            margin-right: 0rem;
            width: 90%;
            margin-bottom: 0.5rem;
            
            

        }
        @include mobile_landscape{
            margin-right: 0rem;
        }
        .balance{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            margin-right: -1rem;
            min-width: 10rem;
            height: 2rem;
            // box-shadow: 0px 0px 3px #fff;
            border-radius: 5px;
            position: relative;
            padding: 0.5rem 1rem;
            @include mobile_potrait{
                min-width: 10rem;
                height: 1.5rem;
            }
            @include mobile_landscape{
                min-width: 7rem;
                height: 1.3rem;
                margin-right: 0.2rem;
            }
            p{
                color: #fff;
                font-size: 1rem;
                text-transform: uppercase;
                text-shadow: 3px 3px 5px #000;
                font-weight: normal;
                z-index: 1;
                margin-right: 1rem;
                
                span{
                    color: #f43e98;
                    font-weight: 600;
                }
                @include mobile_potrait{
                    font-size: 0.8rem;
                    margin: 0.2rem;
                    margin-right: 0.5rem;
                }
                @include mobile_landscape{
                    font-size: 0.6rem;
                    margin: 0.2rem;
                }
            }
            img{
                width: 100%;
                height: 100%;
                bottom: 0;
                right: 0;
                position: absolute;
            }
        }
        .connectBtn{
            padding: 0.5rem 2vw;
            border-radius: 10px;
            background-image: linear-gradient(to right, #f43e98, #4226d9) ;
            // background-color: rgba(255, 255, 255, 0);
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            transition: all 0.3s ease;
            margin-bottom: 0.2rem;
            margin-left: 2rem;
            @include mobile_potrait{
                font-size: 0.8rem;
                padding: 0.5rem 0.5rem ;
                margin-right: 0rem;
                border-radius: 5px;
            }
            @include mobile_landscape{
                font-size: 0.8rem;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                margin-left : 1rem;
            }
            
            p{
                z-index: 1;
                color: #fff;
                font-size: 1rem;
                @include mobile_potrait{
                    font-size: 0.8rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
            }
            img{
                width: 100%;
                height: 100%;
                position: absolute;
                transition: all 0.3s ease;
            }
            &:hover{
                img{
                    filter: drop-shadow(0px 0px 3px #fff);
                }
            }
            
        }
        
        .socialLinks{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            @include mobile_potrait{
                display: none;
            }
            @include mobile_landscape{
                display: none;
            }
            a{
                font-size: 1.5rem;
                padding: 0.2rem;
                width: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(255, 255, 255);
                position: relative;
                text-shadow: 1px 1px 0px #000;
                margin-left: 0.5rem;
                text-decoration: none;
                @include mobile_potrait{
                    font-size: 1rem;
                    margin-right: 0rem;
                    width: 1.5rem;
                }
                @include mobile_landscape{
                    font-size: 1rem;
                    width: auto;
                    padding: 0.3rem;
                }
                .fab{
                    z-index: 1;
                }
                img{
                    height: 2rem;
                    @include mobile_potrait{
                        height: 1.3rem;
                    }
                    @include mobile_landscape{
                        height: 1.3rem;
                    }
                }
                &:hover{
                    animation: btnAni 0.5s ease 0s 1 ;
                    @keyframes btnAni {
                        0%{
                            transform: rotate(0);
                        }
                        20%{
                            transform: rotate(-10deg);
                        }
                        40%{
                            transform: rotate(10deg);
                        }
                        60%{
                            transform: rotate(-10deg);
                        }
                        80%{
                            transform: rotate(10deg);
                        }
                        100%{
                            transform: rotate(0);
                        }
                    }
                }
            }
        }
    }
    .navList{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include mobile_potrait{
            display: none;
        }
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            padding: 0;
            @include mobile_potrait{
                display: none;
            }
            @include mobile_landscape{
                padding: 0.5rem;
            }
            li{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-right: 1vw;
                @include mobile_landscape{
                    margin-right: 1vw;
                }

                a{
                    font-size: 1rem;
                    font-weight: normal;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    color: rgb(255, 255, 255);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                    &:hover{
                        text-shadow: 3px 3px 0px black;
                    }
                }
                .drop{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    color: #ffffff;
                    cursor: pointer;
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                    .fas{
                        margin-left: 0.5rem;
                    }
                    .dropContent{
                        position: absolute;
                        width: 8rem;
                        top: 100%;
                        left: 0;
                        height: 0;
                        overflow: hidden;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        background-color: #ffffff22;
                        
                        border-radius: 10px;
                        a{
                            margin-bottom: 0.5rem;
                        }

                    }
                    .activeDrop{
                        height: auto;
                        padding: 0.5rem;
                    }
                }
                
            }
            .selected{
                a{
                    color: rgb(0, 225, 255);
                }
            }
        }
        
    }

    
    .hamburger{
        display: none;
        width: 2.5rem;
        height: 3rem;
        position: absolute;
        right : 2rem;
        top : 1rem;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        z-index: 6;
        overflow: hidden;
        transform: scale(0.9);
        @include mobile_potrait{
            display: flex;
            width: 2rem;
            height: 2rem;
            right : 0.2rem;
            top : 0.5rem;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
        }
        @include mobile_landscape{
            width: 2.5rem;
            height: 2.2rem;
            right : 1rem;
            top : 1.3rem;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
        }
        span{
            width: 100%;
            height: 0.3rem;
            border-radius: 3px;
            background-color: #fff;
            transform-origin: left;
            transition: all 1s ease;
        }
        &.active{
            z-index: 1;
            span{
                &:first-child{
                    width: 2.2rem;
                    transform: rotate(40deg);
                    @include mobile_potrait{
                        transform: rotate(35deg);
                    }
                    @include mobile_landscape{
                        transform: rotate(30deg);
                    }
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    width: 2.2rem;
                    transform: rotate(-40deg);
                    @include mobile_potrait{
                        transform: rotate(-35deg);
                    }
                    @include mobile_landscape{
                        transform: rotate(-30deg);
                    }
                }

            }
        }
    }
}