@import "../../global.scss";
.document{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    @include mobile_potrait{
    }

    .documentContent{
        width: 80%;
        display: flex;
        z-index: 1;
        margin-top: 5rem;
        margin-bottom: 5rem;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            width: 100%;
            height: 90%;
            flex-direction: column;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
        h1{
            font-size: 3rem;
            color: white;
            width: 90%;
            font-family: ZenDots;
            text-align: center;
            margin-top: 1rem;
            @include mobile_potrait{
                font-size: 1.5rem;
                text-align: center;
            }
            @include mobile_landscape{
                font-size: 1.5rem;
            }
        }
        p{
            font-size: 1rem;
            color:#aaa;
            width: 90%;
            text-align: center;
            @include mobile_potrait{
                font-size: 0.8rem;
            }
            @include mobile_landscape{
                font-size: 0.8rem;
            }
        }
        .wrapper{
            width: 100%;
            display: flex;
            max-width: 1440px;
            padding-top: 1rem;
            padding-bottom: 2rem;
            align-items: center;
            justify-content: center;
            
                ul{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row;
                    width: 90%;
                    padding: 0;
                    margin: 0;
                    z-index: 1;
                    li{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;
                        width: 22%;
                        min-height: 10rem;
                        min-width: 10rem;
                        margin-top: 1rem;
                        transition: all 0.3s ease;
                        cursor: pointer;
                        
                        @include mobile_potrait{
                            width: 22%;
                            min-width:  22%;
                            height: 5rem;
                            min-height: 5rem;
                            margin-top: 0.5rem;
                        }
                        @include mobile_landscape{
                            width: 22%;
                            min-width:  22%;
                            height: 6rem;
                            min-height: 6rem;
                        }
                        a{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            text-decoration: none;
                        }
                        img{
                            width: 3rem;
                            opacity: 0;
                            transition: all 0.3s ease;
                            @include mobile_potrait{
                                width: 2rem;
                            }
                            @include mobile_landscape{
                                width: 2rem;
                            }
                        }
                        p{
                            font-size: 1rem;
                            color: #fff;
                            text-align: center;
                            width: 90%;
                            font-family: ZenDots;
                            text-transform: uppercase;
                            transition: all 0.3s ease;
                            @include mobile_potrait{
                                font-size: 0.5rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.6rem;
                            }
                        }
                        .bar{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0.6;
                            
                        }
                        &:hover{
                            margin-bottom: 1rem;
                            margin-top: 0rem;
                            img{
                                opacity: 1;
                            }
                            .bar{
                                opacity: 1;
                            }
                            P{
                                color: #C004DE;
                            }
                        }
                    }
               
            }
        }
       
        .bg1{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}