@import "../../global.scss";
.creation{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    @include mobile_potrait{
    }

    .creationContent{
        width: 90%;
        z-index: 1;
        margin-top: 5rem;
        margin-bottom: 5rem;
        position: relative;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            margin-top: 3rem;
            margin-bottom: 3rem;
            flex-direction: column;
        }
        h1{
            font-size: 3rem;
            color: white;
            font-family: ZenDots;
            @include mobile_potrait{
                font-size: 1.5rem;
                text-align: center;
            }
            @include mobile_landscape{
                font-size: 1.5rem;
            }
        }
        p{
            font-size: 1rem;
            color:#aaa;
            @include mobile_potrait{
                font-size: 0.8rem;
                text-align: center;
            }
            @include mobile_landscape{
                font-size: 0.8rem;
            }
        }
        .wrapper{
            width: 100%;
            max-width: 1440px;
            padding-top: 2rem;
            padding-bottom: 2rem;
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .item{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 300px;
                min-height: 320px;
                height: 320px;
                position: relative;
                padding: 2rem;
                margin-bottom: 2rem;
                cursor: pointer;
                transition: all 0.3s ease;
                @include mobile_potrait{
                    transform: scale(0.8);
                    margin-bottom: 0rem;
                }
                @include mobile_landscape{
                    width: 220px;
                    min-height: 300px;
                    height: 300px;
                }
                .itemContent{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    z-index: 1;
                    border-radius: 1rem;
                    overflow: hidden;
                    position: relative;
                    .imgContainer{
                        width: 100%;
                        height: calc(100%);
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 110%;
                        }
                    }
                    h2{
                        height: 4rem;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        background-color: #5c036a76;
                        font-family: ZenDots;
                        font-size: 1rem;
                        position: absolute;
                        bottom: 0;
                        z-index: 1;
                        backdrop-filter: blur(5px);
                    }


                }
                .imgContainer{

                }
                .bar{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    opacity: 0.6;
                    transition: all 0.3s ease;
                }
                &:hover{
                    transform: scale(1.05);
                    .bar{
                        opacity: 1;
                    }
                }

            }
        }
       
        .bg1{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    .effect{
        width: 50vw;
        height: 50vw;
        position: absolute;
        border-radius: 50%;
        background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
        opacity: 0.3;
        z-index: 0;
        right: -20vw;
    }
    .effect1{
        width: 40vw;
        height: 40vw;
        position: absolute;
        border-radius: 50%;
        background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
        opacity: 0.2;
        z-index: 0;
        left: -20vw;
        bottom : 10%;
    }
}