@import "global.scss";
.app{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    
}
.page{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #1e0125;
    position: relative;
    .sections{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        z-index: 1;
        
    }
}

@keyframes textAni {
    0%{
        background-position-y: 0;
    }
    100%{
        background-position-y: -100%;
    }
}
.myToast{
    left: calc(50% - 75px) !important;
    width: 300px !important;
    background: #240046 !important;
    border: 1px solid #b904d6;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    >div{
        color: #fff;
        width: fit-content !important;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row !important;
        font-size: 14px;
    }
    
}
.bg{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    @include mobile_potrait{
        height: 100%;
    }
    @include mobile_landscape{
        height: 100%;
    }
}
.button{
    font-size: 1rem;
    padding : 0.5rem 2rem;
    border-radius: 10px;
    background-image: linear-gradient(to right, #f43e98, #4226d9) ;
    // background-color: rgba(255, 255, 255, 0);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #fff;
    @include mobile_potrait{
        font-size: 0.8rem;
        border-radius: 5px;
    }
    @include mobile_landscape{
        font-size: 0.8rem;
        margin-left: 1rem;
    }
    &:hover{
        background-image: linear-gradient(to right, #ff67b3, #6549ff) ;
    }
}
