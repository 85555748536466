

$radius: 170px;
$quarter: ($radius / 2) + ($radius / 5.5);

@mixin balls($delay) {
    animation-fill-mode: both;
    position: absolute;
    opacity: 0;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 2px;
    animation: ball-spin-fade-loader 1s $delay infinite linear;
    @keyframes ball-spin-fade-loader {
        0%   {
            opacity: 0;
            transform: scale(0);
        }
        25%   {
            opacity: 0;
            transform: scale(0);
        }
        50% {
            opacity: 0.1;
            transform: scale(0.2);
        }
        70% {
            opacity: 0.3;
            transform: scale(0.5);
        }
        85% {
            opacity: 0.5;
            transform: scale(1);
        }
        100% {
            opacity: 0;
            transform: scale(0);
        }
    }
  }
.loading_page{
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    z-index: 999;
    
    .logoIcon{
        height: 120px;
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        opacity: 1;
        position: absolute;
        @media screen and (max-width: 768px) and (orientation:portrait){
            height: 70px;
        }
        @media screen and (max-height: 768px) and (orientation:landscape){
            height: 70px;
        }
        @keyframes loading {
            50%  {opacity:0.3}
            100% {opacity:1}
        }
    }
    h1{
        font-size: 3rem;
        color: #fff;
    }
}


