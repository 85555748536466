@import "../../global.scss";
.tokenStaking{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    // background-image: linear-gradient(190deg, #a9e7ff, #06abf7);
    z-index: 1;
    @include mobile_potrait{
        background-size: cover;
    }
    @include mobile_landscape{
        // height: auto !important;
    }
    .scroll{
        position: absolute;
        top :-5rem
    }
    .tokenStakingContent{
        width: 90%;
        z-index: 2;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5rem;
        @include mobile_potrait{
            width: 90%;
            margin-top: 7rem;
            height: auto;
        }
        @include mobile_landscape{
            width: 90%;
            margin-top: 3rem;
            height: calc(100% - 3rem);
        }
        h1{
            font-size: 3rem;
            font-weight: 600;
            color: #fff;
            text-align: center;
            text-shadow: 2px 2px 0px #000,-2px -2px 0px #000,-2px 2px 0px #000,2px -2px 0px #000,3px 3px 10px #000,2px 2px 3px #000;
            width: 100%;
            text-align: center;           
            line-height: 1; 
            font-family: ZenDots;
            margin-bottom: 2rem;
            @include mobile_potrait{
                font-size: 1.5rem;
                width: 90%;
                margin-bottom: 1rem;
                line-height: 1;

                
            }
            @include mobile_landscape{
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
        }
        p{
            z-index: 1;
            font-size: 1.2rem;
            color: rgb(255, 255, 255);
            width: 90%;
            text-align: center;
            margin-bottom: 0.5rem;
            @include mobile_potrait{
                font-size: 0.8rem;
                line-height: 1;
            }
            @include mobile_landscape{
                font-size: 0.8rem;
            }
        }
        .desc{
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 1rem;
            h3{
                z-index: 1;
                font-size: 1.2rem;
                color: rgb(255, 255, 255);
                width: auto;
                margin-bottom: 0rem;
                font-weight: 500;
                @include mobile_potrait{
                    font-size: 0.8rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
            }
            p{
                z-index: 1;
                font-size: 1rem;
                color: rgb(255, 255, 255);
                width: auto;
                margin-bottom: 0rem;
                @include mobile_potrait{
                    font-size: 0.8rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
            }
        }
        .tokenStakingWrapper{
            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            flex-wrap: wrap;
            min-height: 60vh;
            @include mobile_potrait{
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: auto;
            }
            @include mobile_landscape{
                width: 100%;
                justify-content: center;
            }
            &::-webkit-scrollbar{
                display: none;
            }
            .stakingWrapper{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 1rem;
                width: calc(50% - 4rem);
                max-width: 540px;
                height: fit-content;
                margin-bottom: 2rem;
                padding: 2rem;
                position: relative;
                @include mobile_potrait{
                    margin-bottom: 1rem;
                    width: calc(100% - 2rem);
                    padding: 0.5rem;
                }
                @include mobile_landscape{
                    width: 60%;
                    padding: 2rem;
                }
                
                .header{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    margin-bottom: 1rem;
                    position: relative;
                    padding-bottom: 0.5rem;
                    .logoImg{
                        width: 5rem;
                        margin-right: 1rem;
                        filter: drop-shadow(3px 3px 5px #000);
                        z-index: 1;
                        @include mobile_potrait{
                            width: 3rem;
                            margin-right: 0.5rem;
                        }
                        @include mobile_landscape{
                            width: 3rem;
                        }
                    }
                    h2{
                        z-index: 1;
                        font-size: 1.5rem;
                        color: rgb(255, 255, 255);
                        width: auto;
                        font-weight: 500;
                        margin-bottom: 0rem;
                        text-shadow: 3px 3px 5px #000;
                        font-family: ZenDots;
                        @include mobile_potrait{
                            font-size: 1.2rem;
                        }
                        @include mobile_landscape{
                            font-size: 1.2rem;
                        }
                    }
                    .bar{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        border-radius: 10px;
                        box-shadow: 3px 3px 5px #000;
                        opacity: 0.5;
                    }
                }
                .desc{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 1rem;
                    h3{
                        z-index: 1;
                        font-size: 1.2rem;
                        color: rgb(255, 255, 255);
                        width: auto;
                        margin-bottom: 0rem;
                        font-weight: 500;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                        }
                    }
                    p{
                        z-index: 1;
                        font-size: 1.2rem;
                        color: rgb(255, 255, 255);
                        width: auto;
                        margin-bottom: 0rem;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                        }
                    }
                }
                .btns{
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    width: calc(100% - 2rem);
                    flex-direction: column;
                    position: relative;
                    padding-top: 2vh;
                    padding-bottom: 0.5vh;
                    margin-bottom: 1rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    @include mobile_potrait{
                        padding-top: 1rem;
                        padding-bottom: 0.5rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                    span{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: row;
                    }
                    p{
                        z-index: 1;
                        font-size: 1rem;
                        color: rgb(255, 255, 255);
                        width: auto;
                        margin-bottom: 0rem;
                        width: 50%;
                        text-align: end;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                            margin-left: 0rem;
                            margin-bottom: 0.5rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                        }
                    }
                    .lft{
                        text-align: left;
                        text-shadow: 1px 1px 0px #000,-1px -1px 0px #000,-1px 1px 0px #000,1px -1px 0px #000;
                    }
                    button{
                        font-weight: 700;
                        width: 8rem;
                        height: 2.5rem;
                        border: none;
                        border-radius: 10px;
                        background-image: linear-gradient(to right, #f43e98, #4226d9) ;margin-bottom: 0.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.3s ease;
                        position: relative;
                        cursor: pointer;
        
                        &:disabled{
                            background-image: linear-gradient(to right, #a5a5a5, #686868) ;
                            p{
                                color: #aaa;
                                text-shadow: none;
                            }
                        }
                        @include mobile_potrait{
                            margin: 0.2rem;
                            width: 6rem;
                            height: 2rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                            width: 6rem;
                            height: 2rem;
                        }
                        &:hover{
                            img{
                                filter: drop-shadow(0px 0px 3px #fff);
                            }

                        }
                        p{
                            z-index: 1;
                            margin-bottom: 0rem;
                            font-size: 1rem;
                            color: rgb(255, 255, 255);
                            text-shadow: 1px 1px 0px #000,-1px -1px 0px #000,-1px 1px 0px #000,1px -1px 0px #000,3px 3px 5px #000,2px 2px 3px #000;
                            text-transform: uppercase;
                            width: auto;
                            font-weight: normal;
                            margin-left: 0rem;
                            @include mobile_potrait{
                                font-size: 0.7rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.8rem;
                            }
                        }
                    }
                    .bar{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                    .refund{
                        opacity: 0;
                    }
                    
                }
                .contribute{
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-direction: column;
                    border-radius: 3rem;
                    width: 100%;
                    margin-bottom: 1rem;
                    
                    @include mobile_potrait{
                    }
                    @include mobile_landscape{
                        width: 90%;
                    }
                    h3{
                        z-index: 1;
                        font-size: 1.2rem;
                        color: rgb(255, 255, 255);
                        width: auto;
                        margin-bottom: 1rem;
                        font-weight: 500;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                        }
                    }

                    span{
                        width: 100%;
                        height: 54px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 0.5rem;
                        border-radius: 10px;
                        position: relative;
                        padding-top: 1rem;
                        padding-bottom: 0.5rem;
                        background-size: 100% 100%;
                        // padding-left: 1rem;
                        // padding-right: 1rem;
                        @include mobile_potrait{
                            height: 45px;
                            margin-bottom: 0.5rem;
                        }
                        @include mobile_landscape{
                            margin-bottom: 0.5rem;
                            height: 45px;
                        }
                        p{
                            z-index: 1;
                            font-size: 1rem;
                            color: rgb(255, 255, 255);
                            // text-shadow:  2px 2px 0px rgb(52, 82, 252), -2px -2px 0px rgb(52, 82, 252), 2px -2px 0px rgb(52, 82, 252), -2px 2px 0px rgb(52, 82, 252),2px 2px 5px #000000;
                            text-transform: uxppercase;
                            width: auto;
                            margin-bottom: 0rem;
                            @include mobile_potrait{
                                font-size: 0.8rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.8rem;
                            }
                        }
                        input{
                            width: 6rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1rem;
                            font-weight: 700;
                            color: #fff;
                            background-color: rgba(255, 255, 255, 0.103);
                            font-family: 'Space Mono', monospace;
                            text-shadow:  1px 1px 0px #000, -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000,2px 2px 5px #000000;
                            margin: 0rem 0.5rem 0rem 0.5rem;
                            border: none;
                            text-align: left;
                            z-index: 1;
                            padding: 0.5rem;
                            border-radius: 5px;
                            // box-shadow: 0px 0px 5px #ff3f04;
                            // box-shadow: 0px 0px 5px #ff040400;
                            &:focus{
                                outline: none;
                            }
                            @include mobile_potrait{
                                font-size: 1rem;
                                width: 3rem;
                            }
                            @include mobile_landscape{
                                font-size: 1rem;
                                width: 3rem;
                            }
    
                        }
                        input[type=number]::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                          }
                          .alrt{
                              position: absolute;
                              bottom: 0rem;
                              font-size: 0.8rem;
                              left: 0.5rem;
                              color: #ff3f04;
                              @include mobile_potrait{
                                font-size: 0.6rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.6rem;
                            }
                          }
                        button{
                            font-weight: 700;
                            padding: 0.5rem 1rem;
                            border: none;
                            border-radius: 10px;
                            background-image: linear-gradient(to right, #f43e98, #4226d9) ;
                            margin-bottom: 0.5rem;
                            margin-bottom: 0.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all 0.3s ease;
                            position: relative;
                            cursor: pointer;
            
                            &:disabled{
                                background-image: linear-gradient(to right, #a5a5a5, #686868) ;
                            
                                p{
                                    color: #fff;
                                    text-shadow: none;
                                }
                                img{
                                    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0)) !important;
                                    filter: grayscale(1) !important;
                                }
                            }
                            @include mobile_potrait{
                                margin: 0.2rem;
                                min-width: 10rem;
                                padding: 0.5rem 1rem;
                            }
                            @include mobile_landscape{
                                font-size: 0.8rem;
                                margin: 0.2rem;
                                min-width: 10rem;
                                padding: 0.5rem 1rem;
                            }
                            
    
                            p{
                                z-index: 1;
                                margin-bottom: 0rem;
                                font-size: 1rem;
                                color: #ffffff;
                                // text-shadow: 1px 1px 0px #000,-1px -1px 0px #000,-1px 1px 0px #000,1px -1px 0px #000,3px 3px 5px #000,2px 2px 3px #000;
                                text-transform: uppercase;
                                letter-spacing: 2px;
                                width: auto;
                                font-weight: normal;
                                @include mobile_potrait{
                                    font-size: 0.8rem;
                                }
                                @include mobile_landscape{
                                    font-size: 0.8rem;
                                }
                            }
                            &:hover{
                                img{
                                    filter: drop-shadow(0px 0px 3px #fff);
                                }
                            }
                            img{
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                transition: all 0.3s ease;
                            }
                        }
                        .bar{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            bottom: 0;
                        }
                        
                    }
                    p{
                        z-index: 1;
                        font-size: 1rem;
                        color: rgb(255, 255, 255);
                        width: 100%;
                        text-align: left !important;
                        margin-bottom: 0rem;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                            line-height: 1;
                        }
                        @include mobile_landscape{
                            font-size: 0.81rem;
                        }
                    }
    
                }

                .state{
                    width: calc(100% - 2rem);
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-between;
                    border-radius: 10px;
                    position: relative;
                    padding-top: 2vh;
                    padding-bottom: 2vh;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    @include mobile_potrait{
                        margin-bottom: 0.5rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;

                    }
                    @include mobile_landscape{
                        margin-bottom: 0.5rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }
                    span{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: row;

                    }
                    p{
                        z-index: 1;
                        margin-left: 0rem;
                        font-size: 1rem;
                        color: rgb(255, 255, 255);
                        width: auto;
                        margin-bottom: 0rem;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                            margin-bottom: 0.5rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.7rem;
                        }
                    }
                    .lft{
                        text-shadow: 1px 1px 0px #000,-1px -1px 0px #000,-1px 1px 0px #000,1px -1px 0px #000;
                    }
                    a{
                        z-index: 1;
                        margin-left: 0rem;
                        font-size: 1.2rem;
                        color: #aaa;
                        letter-spacing: 2px;
                        width: auto;
                        margin-bottom: 0rem;
                        text-decoration: none;
                        margin-top: 1rem;
                        transition: all 0.3s ease;
                        &:hover{
                            color: #fff;
                        }
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.81rem;
                        }
                    }
                    .bar{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                    }
                    
                }
                .cardBg{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: -1;
                }

            }
            .noData{
                width: 100%;
                height: 100%;
                min-height: 60vh;
                display: flex;
                align-items: center;
                justify-content: center;
                h2{
                    font-size: 3rem;
                    color: #fff;
                    text-align: center;
                    @include mobile_potrait{
                        font-size: 1rem;
                    }
                    @include mobile_landscape{
                        font-size: 1rem;
                    }
                }
            }
        }
        .detail{
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 1rem;
            .detailBtn{
                cursor: pointer;
                color: #aaa;
                font-size: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                transition: all 0.3s ease;
                &:hover{
                    color: #fff;
                }
                @include mobile_potrait{
                    font-size: 1rem;
                }
                @include mobile_landscape{
                    font-size: 1rem;
                }
                .fas{
                    margin-left: 0.5rem;
                }
            }
            div{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                p{
                    z-index: 1;
                    margin-right: 1rem;
                    font-size: 1rem;
                    color: rgb(255, 255, 255);
                    text-shadow: 1px 1px 0px #000,-1px -1px 0px #000,-1px 1px 0px #000,1px -1px 0px #000;
                    width: auto;
                    @include mobile_potrait{
                        font-size: 0.8rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
            }
            
        }
                                                                .icoBox {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 1rem;
            margin-bottom: 5rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            position: relative;
            @include mobile_potrait{
                flex-direction: column;
                width: 80%;
            }
            @include mobile_landscape{
                padding-top: 1rem;
                padding-bottom: 1rem;
                width: 80%;
            }
            .left{
                flex: 0.5;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                @include mobile_potrait{
                    margin-bottom: 2rem;
                }
                h2{
                    z-index: 1;
                    margin-right: 0.5rem;
                    font-size: 2rem;
                    color: rgb(255, 255, 255);
                    width: auto;
                    font-weight: 500;
                    @include mobile_potrait{
                        font-size: 1.2rem;
                    }
                    @include mobile_landscape{
                        font-size: 1.2rem;
                    }
                }
                h3{
                    z-index: 1;
                    margin-right: 1rem;
                    font-size: 1.5rem;
                    color: rgb(255, 255, 255);
                    width: auto;
                    font-weight: 500;
                    @include mobile_potrait{
                        font-size: 1rem;
                    }
                    @include mobile_landscape{
                        font-size:1rem;
                    }
                }
                p{
                    z-index: 1;
                    margin-right: 1rem;
                    font-size: 1rem;
                    color: rgb(255, 255, 255);
                    width: auto;
                    @include mobile_potrait{
                        font-size: 0.6rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                }
                ul{
                    margin-bottom: 0.5rem;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    li{
                        z-index: 1;
                        font-size: 1rem;
                        color: rgb(255, 255, 255);
                        width: auto;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.6rem;
                        }
                    }
                }
                a{
                    z-index: 1;
                    margin-left: 0rem;
                    font-size: 1.2rem;
                    color: #aaa;
                    // text-shadow:  2px 2px 0px rgb(52, 82, 252), -2px -2px 0px rgb(52, 82, 252), 2px -2px 0px rgb(52, 82, 252), -2px 2px 0px rgb(52, 82, 252),2px 2px 5px #000000;
                    letter-spacing: 2px;
                    width: auto;
                    margin-bottom: 0rem;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    &:hover{
                        color: #fff;
                    }
                    @include mobile_potrait{
                        font-size: 0.8rem;
                        margin-left: 30%;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                    
                }
                .readmore{
                    padding: 0.8rem 1rem;
                    p{
                        margin-bottom: 0rem;
                        margin-right: 0rem;
                    }
                    &:hover{
                        img{
                            filter: drop-shadow(0px 0px 3px #fff);
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        transition: all 0.3s ease;
                        border-radius: 0rem;
                    } 
                }
            }
            .right{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 1;
                img{
                    width: 15rem;
                    border-radius: 1rem;
                    @include mobile_potrait{
                        width: 10rem;
                        margin-bottom: 1rem;
                    }
                    @include mobile_landscape{
                        width: 10rem;
                        margin-bottom: 1rem;
                    }
                }
                h2{
                    z-index: 1;
                    margin-right: 1rem;
                    font-size: 2rem;
                    color: rgb(255, 255, 255);
                    width: auto;
                    font-weight: 500;
                    @include mobile_potrait{
                        font-size: 1.2rem;
                        margin-bottom: 1rem;
                    }
                    @include mobile_landscape{
                        font-size: 1.2rem;
                    }
                }
                p{
                    z-index: 1;
                    margin-right: 1rem;
                    font-size: 1rem;
                    color: rgb(255, 255, 255);
                    width: auto;
                    @include mobile_potrait{
                        font-size: 0.8rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
                a{
                    z-index: 1;
                    margin-right: 1rem;
                    font-size: 1.2rem;
                    color: rgb(255, 255, 255);
                    width: auto;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    @include mobile_potrait{
                        font-size: 0.8rem;
                        margin-top: 1rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                        margin-top: 1rem;
                    }
                }
                .apply{
                    padding: 0.8rem 1rem;
                    p{
                        margin-bottom: 0rem;
                        margin-right: 0rem;
                    }
                    &:hover{
                        img{
                            filter: drop-shadow(0px 0px 3px #fff);
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        transition: all 0.3s ease;
                        border-radius: 0rem;
                        @include mobile_potrait{
                            margin-bottom: 0rem;
                        }
                        @include mobile_landscape{
                            margin-bottom: 0rem;
                        }
                    }
                }
            }
            .bar{
                width: 120%;
                height: 100%;
                position: absolute;
                z-index: -1;
                @include mobile_potrait{
                    display: none;
                }
                @include mobile_landscape{
                    margin-bottom: 0rem;
                }
            }
            .bar_mob{
                width: 120%;
                height: 100%;
                position: absolute;
                z-index: -1;
                display: none;
                @include mobile_potrait{
                    display: flex;
                }
                @include mobile_landscape{
                    margin-bottom: 0rem;
                }
            }
        }
    }
    .mintNft{
        position: absolute;
        z-index: 1;
        width: 60%;
        left: 0;
        @include mobile_potrait{
            width: 100%;
            transform: scale(2.5);
        }
        @include mobile_landscape{
        }
    }
}
