@import "../../global.scss";
.parallaxAsProp{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    // height: 100vh;
    // bottom: 0;
    @include mobile_potrait{
    }
    @include mobile_landscape{
    }
    img{
        width: 100%;
        height: 100%;
        @include mobile_potrait{
            width: auto;
            height: 150%;
        }
        @include mobile_landscape{
            height: auto;
        }
    }
    
}