@import "../../global.scss";
.community{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    @include mobile_potrait{
    }
    .scroll{
        position: absolute;
        top :-5rem
    }
    .communityContent{
        width: 80%;
        display: flex;
        z-index: 1;
        margin-top: 5rem;
        margin-bottom: 5rem;
        position: relative;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
        }
        .wrapper{
            width: 100%;
            display: flex;
            max-width: 1440px;
            padding-top: 2rem;
            padding-bottom: 2rem;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include mobile_potrait{
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            @include mobile_landscape{
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            h1{
                font-size: 3rem;
                color: white;
                font-family: ZenDots;
                margin-bottom: 1rem;
                z-index: 1;
                @include mobile_potrait{
                    font-size: 1.5rem;
                    text-align: center;
                }
                @include mobile_landscape{
                    font-size: 1.5rem;
                }
                
            }
            p{
                font-size: 1rem;
                color:#aaa;
                width: 90%;
                text-align: center;
                z-index: 1;
                margin-bottom: 1rem;
                @include mobile_potrait{
                    font-size: 0.6rem;
                }
                @include mobile_landscape{
                    font-size: 0.6rem;
                }
            }
            .button{
                z-index: 1;
            }

        }
       
        .bg1{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    .effect1{
        width: 40vw;
        height: 40vw;
        position: absolute;
        border-radius: 50%;
        background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
        opacity: 0.2;
        z-index: 0;
        left: -20vw;
    }
}