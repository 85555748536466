@import "../../global.scss";
.about{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    @include mobile_potrait{
    }
    .scroll{
        position: absolute;
        top :-5rem
    }
    .aboutContent{
        width: 80%;
        display: flex;
        z-index: 1;
        margin-top: 5rem;
        margin-bottom: 5rem;
        position: relative;
        align-items: center;
        justify-content: center;
        @include mobile_potrait{
            width: 90%;
            height: 90%;
            flex-direction: column;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
        @include mobile_landscape{
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
        .wrapper{
            width: 90%;
            display: flex;
            max-width: 1440px;
            padding-top: 2rem;
            padding-bottom: 2rem;

            @include mobile_potrait{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width:  90%;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            @include mobile_landscape{
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            .left{
                flex:0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h1{
                    font-size: 3vw;
                    color: white;
                    width: 90%;
                    font-family: ZenDots;
                    @include mobile_potrait{
                        font-size: 1.5rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 1.5rem;
                    }
                }
                p{
                    font-size: 1rem;
                    color:#aaa;
                    width: 90%;
                    @include mobile_potrait{
                        font-size: 0.8rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 0.6rem;
                    }
                }
            }
            .right{
                flex:0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include mobile_potrait{
                    width: 100%;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
                ul{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row;
                    width: 90%;
                    padding: 0;
                    margin: 0;
                    @include mobile_potrait{
                        width: 100%;
                        margin-top: 1rem;
                    }
                    @include mobile_landscape{
                    }
                    li{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;
                        width: 30%;
                        min-height: 10rem;
                        min-width: 10rem;
                        margin: 0.5rem;
                        @include mobile_potrait{
                            min-height: 6rem;
                            min-width: 30%;
                        }
                        @include mobile_landscape{
                            min-height: 6rem;
                            min-width: 30%;
                        }
                        img{
                            width: 3rem;
                            @include mobile_potrait{
                                width: 1.5rem;
                            }
                            @include mobile_landscape{
                                width: 1.5rem;
                            }
                        }
                        p{
                            font-size: 1rem;
                            color: #aaa;
                            text-align: center;
                            width: 90%;
                            @include mobile_potrait{
                                font-size: 0.6rem;
                                text-align: center;
                            }
                            @include mobile_landscape{
                                font-size: 0.6rem;
                            }
                        }
                        .bar{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
               
            }
        }
       
        .bg1{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}