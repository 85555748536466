@import "../../global.scss";
.timer{
    width: 90%;
    display: flex;
    flex-direction: row;
    .timerNums{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        flex-direction: row;
        &>div{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .number{
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                color: #FFF;
                font-weight: 700;
                font-size: 3rem;
                line-height: 1;
                // border: 2px solid;
                // border: 3px solid transparent;
                background-image: linear-gradient(to right, #f74882 , #f7488222);
                // border-image-slice: 1;

                padding: 0.5rem;
                min-width: 4rem;
                min-height: 4rem;
                border-radius: 10px;
                font-family: ZenDots;
                position: relative;
                &::before{
                    content: '';
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);

                    background-color: #2e0938;
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    border-radius: 10px;
                }
                @include mobile_potrait{
                    min-width: 2rem;
                    min-height: 1.5rem;
                    font-size: 1.5rem;
                }
                @include mobile_landscape{
                    min-width: 2rem;
                    min-height: 1.5rem;
                    font-size: 1.5rem;
                }
                // *{
                //     font-family: ZenDots;
                // }
                span{
                    z-index: 1;
                }
                
            }
            .text{
                font-size: 1.5rem;
                margin-bottom: 3px;
                overflow: hidden;
                color: #fff;

                @include mobile_potrait{
                    font-size: 0.6rem;
                    margin-top: 0vh;
                }
                @include mobile_landscape{
                    font-size: 0.6rem;
                    margin-top: 0vh;
                }
            }
        }
        
        .line{
            height: 70%;
            border-right: 3px black solid;
        }
    }
}