@import "../../global.scss";
.footer{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #c104de22;
    .footerContent{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 3rem;
        z-index: 1;
        @include mobile_potrait{
            margin-bottom: 2rem;
            flex-direction: column;
        }
        @include mobile_landscape{
            margin-bottom: 2rem;
        }
        .wrapper{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: row;
            width: 80%;
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            @include mobile_potrait{
               flex-direction: column;
            }
            @include mobile_landscape{
            }
            .left{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include mobile_potrait{
                    margin-bottom: 1rem;
                    width: 100%;
                }
                @include mobile_landscape{
                }
                h2{
                    font-size: 2rem;
                    font-weight: 500;
                    color: #fff;
                    margin-bottom: 1rem;
                    @include mobile_potrait{
                        font-size: 1.2rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 1.2rem;
                        text-align: center;
                    }
                }
                p{
                    font-size: 1rem;
                    font-weight: normal;
                    color: #aaa;
                    margin-bottom: 2rem;
                    width: 90%;
                    @include mobile_potrait{
                        font-size: 0.8rem;
                        margin-bottom: 1rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                        margin-bottom: 1rem;
                        text-align: center;
                    }
                    
                }
                .myForm{
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    input{
                        padding: 0.5rem;
                        border: none;
                        background-color: #00000033;
                        border-radius: 10px 0 0 10px;
                        font-size: 1rem;
                        &:focus{
                            outline: none;
                        }
                        @include mobile_potrait{
                            width: 10rem;
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                            margin-bottom: 1rem;
                            text-align: center;
                        }
                        
                    }
                    button{
                        border: none;
                        padding: 0.5rem 1rem;
                    }

                }
            }
            .right{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include mobile_potrait{
                    margin-bottom: 1rem;
                    width: 100%;
                }
                @include mobile_landscape{
                }
                p{
                    font-size: 1rem;
                    font-weight: normal;
                    color: #aaa;
                    margin-bottom: 2rem;
                    width: 90%;
                    @include mobile_potrait{
                        font-size: 0.8rem;
                        margin-bottom: 1rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                        margin-bottom: 1rem;
                        text-align: center;
                    }
                    
                }
                .socialLinks{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    margin-bottom: 1rem;
                    width: 100%;
                    @include mobile_potrait{
                    }
                    a{
                        font-size: 1.5rem;
                        width: 2rem;
                        height:  2rem;
                        border-radius: 5px;
                        background-color: #4b0258;
                        padding: 0.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: rgb(255, 255, 255);
                        position: relative;
                        text-shadow: 1px 1px 0px #000;
                        margin-left: 0.5rem;
                        text-decoration: none;
                        @include mobile_potrait{
                            font-size: 1rem;
                            margin-right: 0rem;
                            width: 1.5rem;
                            height: 1.5rem;
                            font-size: 1rem;
                        }
                        @include mobile_landscape{
                            font-size: 1rem;
                            width: 1.5rem;
                            padding: 0.3rem;
                        }
                        .fab{
                            z-index: 1;
                        }
                        
                        &:hover{
                            animation: btnAni 0.5s ease 0s 1 ;
                            @keyframes btnAni {
                                0%{
                                    transform: rotate(0);
                                }
                                20%{
                                    transform: rotate(-10deg);
                                }
                                40%{
                                    transform: rotate(10deg);
                                }
                                60%{
                                    transform: rotate(-10deg);
                                }
                                80%{
                                    transform: rotate(10deg);
                                }
                                100%{
                                    transform: rotate(0);
                                }
                            }
                        }
                    }
                }
            }
            
        }
        
        span{
            text-align: center;
            color: #fff;
            font-size: 1.5rem;
            width: 80%;
            font-size: 1rem;
            @include mobile_potrait{
                font-size: 0.8rem;
                text-align: center;
            }
            @include mobile_landscape{
                font-size: 0.8rem;
            }
        }
        
    }
    
}