@import "../../global.scss";
.home{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    z-index: 3;
    position: relative;
    @include mobile_potrait{
        height: 100vh;
    }
    .homeContent{
        width: 100%;
        height: calc(100% - 7rem);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        margin-top: 7rem;
        z-index: 1;
        @include mobile_landscape{
            margin-top: 4rem;
            height: calc(100% - 4rem);
        }
        @include mobile_potrait{
            margin-top: 5rem;
            height: calc(100% - 5rem);
        }

        
        .homeWrapper{
            width: 90%;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 1440px;
            z-index: 1;
            @include mobile_potrait{
                height: 80vh;
                flex-direction: column;
                justify-content: center;
            }
            @include mobile_landscape{
                height: 100%;
            }
            .homeLeft{
                flex:0.5;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                @include mobile_potrait{
                    flex:0.4;
                    width: 100%;
                    margin-bottom: 5vh;
                    align-items: center;
                }
                h1{
                    font-size: 3rem;
                    font-weight: 800;
                    color:white;
                    margin-bottom: 2vh;
                    font-family: ZenDots;
                    line-height: 1.1;
                    width: 90%;
                    @include mobile_potrait{
                        font-size: 1.5rem;
                        line-height: 1;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 1.5rem;
                    }
                    span{
                        color: #222;
                        text-shadow: 1px 1px 0px #aaa, -1px -1px 0px #aaa, -1px 1px 0px #aaa, 1px -1px 0px #aaa;
                        font-family: ZenDots;
                    }
                }
                p{
                    font-size: 1rem;
                    color:#aaa;
                    margin-bottom: 5vh;
                    font-family: 'Roboto', sans-serif;
                    width: 90%;
                    @include mobile_potrait{
                        margin-bottom: 3vh;
                        font-size: 0.8rem;
                        text-align: center;
                    }
                    @include mobile_landscape{
                        font-size: 2.5vh;
                    }
                }
                .btns{
                    width: 90%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 1rem;
                    @include mobile_potrait{
                        justify-content: center;
                    }
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: row;
                    width: 90%;
                    margin-top: 1rem;
                    li{
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        width: 30%;
                        img{
                            width: 3rem;
                            @include mobile_potrait{
                                width: 2rem;
                            }
                            @include mobile_landscape{
                                width: 2rem;
                            }
                        }
                        span{
                            width: 100%;
                            h2{
                                color: #fff;
                                font-family: ZenDots;
                                font-size: 1.2rem;
                                margin-bottom: 0.5rem;
                                @include mobile_potrait{
                                    font-size: 0.7rem;
                                }
                                @include mobile_landscape{
                                    font-size: 0.7rem;
                                }
                            }
                            p{
                                width: 100%;
                                margin-bottom: 0rem;
                                @include mobile_potrait{
                                    font-size: 0.6rem;
                                    text-align: left;
                                }
                                @include mobile_landscape{
                                    font-size: 0.6rem;
                                    text-align: left;
                                }
                            }
                        }
                        .br{
                            height: 5rem;
                            border-right: 1px #C004DE solid;
                        }
                    }
                }
            }
            .homeRight{
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;
                position: relative;
                height: 100%;
                @include mobile_potrait{
                    width: 90%;
                    height: 40vh !important;

                }
                @include mobile_landscape{
                }
                img{
                    height: 90%;

                    @include mobile_landscape{
                    }
                }
                .countDown{
                    width: 80%;
                    height: 12rem;
                    background-image: radial-gradient(#2e0938, #2e0938);
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    border-radius: 2vh ;
                    z-index: 1;
                    flex-direction: column;
                    box-shadow: 5px 5px 10px #00000077;
                    @include mobile_potrait{
                        width: 100%;
                        height: 5rem;
                    }
                    @include mobile_landscape{
                        width: 100%;
                        height: 5rem;
                    }
                    p{
                        font-size: 1rem;
                        color:#fff;
                        font-family: ZenDots;
                        margin-bottom: 1rem;
                        margin-top: 1rem;
                        @include mobile_potrait{
                            margin-bottom: 0vh;
                            font-size: 0.8rem;
                            text-align: center;
                        }
                        @include mobile_landscape{
                            font-size: 2.5vh;
                        }
                    }
                    .coming{
                        color: #fff;
                        font-size : 3rem;
                        font-family: ZenDots;
                        padding: 1rem;
                        @include mobile_potrait{
                            font-size: 1rem;
                        }
                        @include mobile_landscape{
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
        .effect{
            width: 50vw;
            height: 50vw;
            position: absolute;
            border-radius: 50%;
            background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
            opacity: 0.3;
            z-index: 0;
            right: 5%
        }
        .effect1{
            width: 40vw;
            height: 40vw;
            position: absolute;
            border-radius: 50%;
            background-image: radial-gradient( #C004DE99, #C004DE55, #C004DE00, #C004DE00);
            opacity: 0.2;
            z-index: 0;
            left: -20vw;
        }
    }
}